import React, { Component } from 'react';
import { MDBRow, MDBCard, MDBCardBody, MDBView, MDBMask, MDBCardTitle, MDBCardText } from 'mdbreact';
import './AboutAsilat.css';
import img1 from '../../images/news/about-asilat-2.jpg';
import img2 from '../../images/news/about-asilat-1.jpg';
import img3 from '../../images/news/about-asilat-3.jpg';

class AboutAsilat extends Component {
  render() {
    return (
        <MDBRow className="d-flex justify-content-center">
          <div className="column-1 px-2 mb-r pt-3">
            <MDBCard className="default-color-dark">
              <MDBView >
              <img src={ img1 } alt="" />
                <MDBMask overlay="white-slight" />
              </MDBView>
              <MDBCardBody className="text-center">
                <MDBCardTitle className="white-text">Asociación Privada</MDBCardTitle>
                <MDBCardText className="white-text">
                    Asilat esta dirigida a empresas pequeñas, medianas y grandes bien sean importadores o exportadores. Incorpora a todos los sectores económicos con énfasis en el comercial, industrial,agrícola y cultural. 
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </div>
          <div className="column-1 px-2 mb-r pt-3">
            <MDBCard className="primary-color-dark">
              <MDBView >
                <img src={ img2 } alt="" />
                <MDBMask overlay="white-slight" />
              </MDBView>
              <MDBCardBody className="text-center">
                <MDBCardTitle className="white-text">Función Principal</MDBCardTitle>
                <MDBCardText className="white-text">
                    Velar por los intereses de los empresarios latinos y turcos que la conforman. Todo ello dentro de entorno de transparencia y competitividad donde prevalece la ausencia de intereses políticos.
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </div>
          <div className="column-1 px-2 pt-3">
            <MDBCard className="warning-color-dark">
              <MDBView>
                <img src={ img3 } alt="" />
                <MDBMask overlay="white-slight" />
              </MDBView>
              <MDBCardBody className="text-center">
                <MDBCardTitle className="white-text">Representación Internacional</MDBCardTitle>
                <MDBCardText className="white-text">
                    Asilat cuenta con sedes y relaciones de alto nivel en Turquía y Latinoamérica. Tales relaciones con diferentes entidades estatales son capitalizadas igualmente en beneficio de los afiliados. 
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </div>
        </MDBRow>
    );
  };
}

export default AboutAsilat;