import React from 'react';
import Navigation from '../Navigation/Navigation';
import News from '../News/News';
import AboutAsilat from '../AboutAsilat/AboutAsilat';
import ParallaxEvents from '../ParallaxEvents/ParallaxEvents';
import ParallaxPartners from '../ParallaxPartners/ParallaxPartners';
import ParallaxAboutAsilat from '../ParallaxAboutAsilat/ParallaxAboutAsilat';
import Purpose from '../Purpose/Purpose';
import CarouselHome from '../CarouselHome/CarouselHome';
import Partners from '../Partners/Partners';
import Footer from '../Footer/Footer';
import './Home.css';

const Home = () => {
    return(
        <React.Fragment>
                <Navigation />
                <CarouselHome />
                <Purpose />
                <ParallaxEvents />
                <News />
                <ParallaxAboutAsilat />
                <AboutAsilat />
                <ParallaxPartners />
                <Partners />
                <Footer />
        </React.Fragment>
    )
}

export default Home;