import React, { Fragment } from 'react';
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask } from
"mdbreact";
import Media from 'react-media';
import img1 from '../../images/carousel-img-1.jpg';
import img22 from '../../images/carousel-img-22.jpg';
import img4 from '../../images/carousel-img-4.jpg';
import img5 from '../../images/carousel-img-5.jpg';
import img6 from '../../images/carousel-img-6.jpg';
import img7 from '../../images/carousel-img-7.jpg';
import img10 from '../../images/carousel-img-10.jpg';

/*const background3 = {
  background: '#200122',
  background: '-webkit-linear-gradient(to right, #6f0000, #200122)',
  background: 'linear-gradient(to right, #6f0000, #200122)',    
  height: 300
}*/

const stylesTitleBig = {
  fontSize: 50,
  fontWeight: 500
}

const stylesTitleMedium = {
  fontSize: 30,
  fontWeight: 400
}

const stylesTitleSmall = {
  fontSize: 20,
  fontWeight: 300
}

const stylesContentBig = {
  fontSize: 20,
  fontWeight: 300
}

const stylesContentMedium = {
  fontSize: 15,
  fontWeight: 200
}

const stylesContentSmall = {
  fontSize: 14,
  fontWeight: 100
}

const insideStyles = {
  backgroundColor: 'rgba(52, 52, 52, 0.6)',
  height: 400,
  position: "absolute",
  top: "80%",
  left: "50%",
  transform: "translate(-50%,-50%)"
};  

const CarouselHome = () => {
    return (
        <MDBCarousel
        activeItem={1}
        length={6}
        showControls={true}
        showIndicators={false}
        className="z-depth-1"
      >
        <MDBCarouselInner>

          <MDBCarouselItem itemId="1">
            <MDBView>
            <img
                className="d-block w-100"
                src={ img1 }
                alt="First slide"
              />

              <div>
                <MDBMask style={ insideStyles } className="flex-center flex-column text-white text-center" >
                  <Media queries={{
                      small: "(max-width: 599px)",
                      medium: "(min-width: 600px) and (max-width: 1199px)",
                      large: "(min-width: 1200px)"
                    }}>
                    {matches => (
                      <Fragment>
                        {matches.small && <p style={ stylesTitleSmall }>MISIONES COMERCIALES</p>}
                        {matches.medium && <p style={ stylesTitleMedium }>MISIONES COMERCIALES</p>}
                        {matches.large && <p style={ stylesTitleBig }>MISIONES COMERCIALES</p>}
                      </Fragment>
                    )}
                    </Media>

                    <Media queries={{
                      small: "(max-width: 599px)",
                      medium: "(min-width: 600px) and (max-width: 1199px)",
                      large: "(min-width: 1200px)"
                    }}>
                    {matches => (
                      <Fragment>
                        {matches.small && <p style={ stylesContentSmall }>Gestión y Acompañamiento en viajes de negocios. Agendamiento de citas de negocios con empresas e instituciones de deferentes sectores</p>}
                        {matches.medium && <p style={ stylesContentMedium }>Gestión de viajes y citas de negocios </p>}
                        {matches.large && <p style={ stylesContentBig }>Gestión de viajes y citas de negocios</p>}
                      </Fragment>
                    )}
                  </Media>
                </MDBMask>
              </div>
            </MDBView>
          </MDBCarouselItem>


          <MDBCarouselItem itemId="2">
            <MDBView>
            <img
                className="d-block w-100"
                src={ img5 }
                alt="First slide"
              />

              <div>
                <MDBMask style={ insideStyles } className="flex-center flex-column text-white text-center" >
                  <Media queries={{
                    small: "(max-width: 599px)",
                    medium: "(min-width: 600px) and (max-width: 1199px)",
                    large: "(min-width: 1200px)"
                  }}>
                  {matches => (
                    <Fragment>
                      {matches.small && <p style={ stylesTitleSmall }>ASESORIA INTERNACIONAL</p>}
                      {matches.medium && <p style={ stylesTitleMedium }>ASESORIA INTERNACIONAL</p>}
                      {matches.large && <p style={ stylesTitleBig }>ASESORIA INTERNACIONAL</p>}
                    </Fragment>
                  )}
                  </Media>

                  <Media queries={{
                    small: "(max-width: 599px)",
                    medium: "(min-width: 600px) and (max-width: 1199px)",
                    large: "(min-width: 1200px)"
                  }}>
                  {matches => (
                    <Fragment>
                      {matches.small && <p style={ stylesContentSmall }>Colocamos a su disposición un equipo experto para la apertura y el manejor de cualquier tipo de relación comercial empresarios latinos y turcos</p>}
                      {matches.medium && <p style={ stylesContentMedium }>Equipo experto para el manejo de relacionales comerciales latino-turcas</p>}
                      {matches.large && <p style={ stylesContentBig }>Equipo experto para el manejo de relaciones comerciales latino-turcas</p>}
                    </Fragment>
                  )}
                  </Media>
                </MDBMask>
              </div>
            </MDBView>
          </MDBCarouselItem>

          <MDBCarouselItem itemId="3">
            <MDBView>
            <img
                className="d-block w-100"
                src={ img4 }
                alt="First slide"
              />

              <div>
                <MDBMask style={ insideStyles } className="flex-center flex-column text-white text-center" >
                <Media queries={{
                      small: "(max-width: 599px)",
                      medium: "(min-width: 600px) and (max-width: 1199px)",
                      large: "(min-width: 1200px)"
                    }}>
                    {matches => (
                      <Fragment>
                        {matches.small && <p style={ stylesTitleSmall }>LOGISTICA MERCANTIL</p>}
                        {matches.medium && <p style={ stylesTitleMedium }>LOGISTICA MERCANTIL</p>}
                        {matches.large && <p style={ stylesTitleBig }>LOGISTICA MERCANTIL</p>}
                      </Fragment>
                    )}
                    </Media>

                    <Media queries={{
                      small: "(max-width: 599px)",
                      medium: "(min-width: 600px) and (max-width: 1199px)",
                      large: "(min-width: 1200px)"
                    }}>
                    {matches => (
                      <Fragment>
                        {matches.small && <p style={ stylesContentSmall }>Verificación de mercancías previa al embarque a destino. Seguimiento de carga de origen a destino</p>}
                        {matches.medium && <p style={ stylesContentMedium }>Verificación y seguimiento de embarques </p>}
                        {matches.large && <p style={ stylesContentBig }>Verificación y seguimiento de embarques</p>}
                      </Fragment>
                    )}
                  </Media>
                </MDBMask>
              </div>
            </MDBView>
          </MDBCarouselItem>

          <MDBCarouselItem itemId="4">
            <MDBView>
            <img
                className="d-block w-100"
                src={ img6 }
                alt="First slide"
              />

              <div>
                <MDBMask style={ insideStyles } className="flex-center flex-column text-white text-center" >
                <Media queries={{
                      small: "(max-width: 599px)",
                      medium: "(min-width: 600px) and (max-width: 1199px)",
                      large: "(min-width: 1200px)"
                    }}>
                    {matches => (
                      <Fragment>
                        {matches.small && <p style={ stylesTitleSmall }>INTERCAMBIOS CULTURALES Y ACADEMICOS</p>}
                        {matches.medium && <p style={ stylesTitleMedium }>INTERCAMBIOS CULTURALES Y ACADEMICOS</p>}
                        {matches.large && <p style={ stylesTitleBig }>INTERCAMBIOS CULTURALES Y ACADEMICOS</p>}
                      </Fragment>
                    )}
                    </Media>

                    <Media queries={{
                      small: "(max-width: 599px)",
                      medium: "(min-width: 600px) and (max-width: 1199px)",
                      large: "(min-width: 1200px)"
                    }}>
                    {matches => (
                      <Fragment>
                        {matches.small && <p style={ stylesContentSmall }>Información entorno a programas educativos, universidades y becas para extranjeros</p>}
                        {matches.medium && <p style={ stylesContentMedium }>Programas educativos, becas y universidades </p>}
                        {matches.large && <p style={ stylesContentBig }>Programas educativos, becas y universidades</p>}
                      </Fragment>
                    )}
                  </Media>
                </MDBMask>
              </div>
            </MDBView>
          </MDBCarouselItem>

          <MDBCarouselItem itemId="5">
            <MDBView>
            <img
                className="d-block w-100"
                src={ img7 }
                alt="First slide"
              />

              <div>
                <MDBMask style={ insideStyles } className="flex-center flex-column text-white text-center" >
                <Media queries={{
                      small: "(max-width: 599px)",
                      medium: "(min-width: 600px) and (max-width: 1199px)",
                      large: "(min-width: 1200px)"
                    }}>
                    {matches => (
                      <Fragment>
                        {matches.small && <p style={ stylesTitleSmall }>RED EMPRESARIAL INTERNACIONAL</p>}
                        {matches.medium && <p style={ stylesTitleMedium }>RED EMPRESARIAL INTERNACIONAL</p>}
                        {matches.large && <p style={ stylesTitleBig }>RED EMPRESARIAL INTERNACIONAL</p>}
                      </Fragment>
                    )}
                    </Media>

                    <Media queries={{
                      small: "(max-width: 599px)",
                      medium: "(min-width: 600px) and (max-width: 1199px)",
                      large: "(min-width: 1200px)"
                    }}>
                    {matches => (
                      <Fragment>
                        {matches.small && <p style={ stylesContentSmall }>Diseñada para llevar a cabo el intercambio de experiencias y la creación de valor compartido</p>}
                        {matches.medium && <p style={ stylesContentMedium }>Intercambio de experiencias y creación de valor compartido </p>}
                        {matches.large && <p style={ stylesContentBig }>Intercambio de experiencias y creación de valor compartido</p>}
                      </Fragment>
                    )}
                  </Media>
                </MDBMask>
              </div>
            </MDBView>
          </MDBCarouselItem>
          
          <MDBCarouselItem itemId="6">
            <MDBView>
            <img
                className="d-block w-100"
                src={ img22 }
                alt="First slide"
              />

              <div>
                <MDBMask style={ insideStyles } className="flex-center flex-column text-white text-center" >
                <Media queries={{
                      small: "(max-width: 599px)",
                      medium: "(min-width: 600px) and (max-width: 1199px)",
                      large: "(min-width: 1200px)"
                    }}>
                    {matches => (
                      <Fragment>
                        {matches.small && <p style={ stylesTitleSmall }>CERTIFICACION DE ALIMENTOS HALAL</p>}
                        {matches.medium && <p style={ stylesTitleMedium }>CERTIFICACION DE ALIMENTOS HALAL</p>}
                        {matches.large && <p style={ stylesTitleBig }>CERTIFICACION DE ALIMENTOS HALAL</p>}
                      </Fragment>
                    )}
                    </Media>

                    <Media queries={{
                      small: "(max-width: 599px)",
                      medium: "(min-width: 600px) and (max-width: 1199px)",
                      large: "(min-width: 1200px)"
                    }}>
                    {matches => (
                      <Fragment>
                        {matches.small && <p style={ stylesContentSmall }>Verificación de prácticas permitidas por la religión musulmana para el tratamiento de alimentos en Turquía</p>}
                        {matches.medium && <p style={ stylesContentMedium }>Verificación de prácticas entono al tratamiento de alimentos </p>}
                        {matches.large && <p style={ stylesContentBig }>Certificación de prácticas entorno al tratamiento de alimentos en Turquía</p>}
                      </Fragment>
                    )}
                  </Media>
                </MDBMask>
              </div>
            </MDBView>
          </MDBCarouselItem>

        </MDBCarouselInner>
      </MDBCarousel>
    );
  }
  

export default CarouselHome;