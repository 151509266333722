import React from "react";
import { MDBMask, MDBView, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import img1 from '../../images/services-1.jpg';
import img2 from '../../images/services-2.jpg';
import img3 from '../../images/services-3.jpg';
import img5 from '../../images/services-5.jpg';
import img6 from '../../images/services-6.jpg';
import img7 from '../../images/services-7.jpg';
import img8 from '../../images/services-8.jpg';
import img9 from '../../images/services-9.jpg';
import img10 from '../../images/services-10.jpg';
import img11 from '../../images/services-11.jpg';


class ServicesList extends React.Component {
  render() {
    return (
      <MDBContainer className="mt-5">
        <MDBRow>
          <MDBCol md="4">
            <MDBView zoom>
              <img
                src={ img1 }
                className="img-fluid"
                alt=""
              />
              <MDBMask className="flex-center" overlay="black-light" ></MDBMask>
              <MDBMask className="flex-center" overlay="black-light">
                <p className="white-text text-center">Traducción Oficial</p>
              </MDBMask>
            </MDBView>
          </MDBCol>

          <MDBCol md="4">
            <MDBView zoom>
              <img
                src={ img2 }
                className="img-fluid"
                alt=""
              />
              <MDBMask className="flex-center" overlay="black-light" ></MDBMask>
              <MDBMask className="flex-center" overlay="black-light">
                <p className="white-text text-center">Asesoría Legal</p>
              </MDBMask>
            </MDBView>
          </MDBCol>

          <MDBCol md="4">
            <MDBView zoom>
              <img
                src={ img3 }
                className="img-fluid"
                alt=""
              />
              <MDBMask className="flex-center" overlay="black-light" ></MDBMask>
              <MDBMask className="flex-center" overlay="black-light">
                <p className="white-text text-center">Supervisión de Negocios y Tratados</p>
              </MDBMask>
            </MDBView>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-4">
          <MDBCol md="4">
            <MDBView zoom>
              <img
                src={ img5 }
                className="img-fluid"
                alt=""
              />
              <MDBMask className="flex-center" overlay="black-light" ></MDBMask>
              <MDBMask className="flex-center" overlay="black-strong">
                <p className="white-text text-center">Directorio Empresarial</p>
              </MDBMask>
            </MDBView>
          </MDBCol>
          <MDBCol md="4">
            <MDBView zoom>
              <img
                src={ img6 }
                className="img-fluid"
                alt=""
              />
              <MDBMask className="flex-center" overlay="black-light" ></MDBMask>
              <MDBMask className="flex-center" overlay="black-light">
                <p className="white-text text-center">Gestión de Ideas y Solicitudes</p>
              </MDBMask>
            </MDBView>
          </MDBCol>
          <MDBCol md="4">
            <MDBView zoom>
              <img
                src={ img7 }
                className="img-fluid"
                alt=""
              />
              <MDBMask className="flex-center" overlay="black-light" ></MDBMask>
              <MDBMask className="flex-center" overlay="black-light">
                <p className="white-text text-center">Salas de Juntas</p>
              </MDBMask>
            </MDBView>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-4">
          <MDBCol md="4">
            <MDBView zoom>
              <img
                src={ img8 }
                className="img-fluid"
                alt=""
              />
              <MDBMask className="flex-center" overlay="black-light" ></MDBMask>
              <MDBMask className="flex-center" overlay="black-light">
                <p className="white-text text-center">Muestras Comerciales</p>
              </MDBMask>
            </MDBView>
          </MDBCol>
          <MDBCol md="4">
            <MDBView zoom>
              <img
                src={ img9 }
                className="img-fluid"
                alt=""
              />
              <MDBMask className="flex-center" overlay="black-light" ></MDBMask>
              <MDBMask className="flex-center" overlay="black-light">
                <p className="white-text text-center">Capacitaciones</p>
              </MDBMask>
            </MDBView>
          </MDBCol>
          <MDBCol md="4">
            <MDBView zoom>
              <img
                src={ img10 }
                className="img-fluid"
                alt=""
              />
              <MDBMask className="flex-center" overlay="black-light" ></MDBMask>
              <MDBMask className="flex-center" overlay="black-light">
                <p className="white-text text-center">Gestión de Misiones Comerciales</p>
              </MDBMask>
            </MDBView>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-4">
          <MDBCol md="4">
            <MDBView zoom>
              <img
                src={ img11 }
                className="img-fluid"
                alt=""
              />
              <MDBMask className="flex-center" overlay="black-light" ></MDBMask>
              <MDBMask className="flex-center" overlay="black-light">
                <p className="white-text text-center">Certificación Halal</p>
              </MDBMask>
            </MDBView>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default ServicesList;