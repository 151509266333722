import React, { Component, Fragment } from 'react';
import Navigation from '../Navigation/Navigation';
import ImagesWrapperTurkey from '../ImagesWrapperTurkey/ImagesWrapperTurkey'
import TurkeyData from '../TurkeyData/TurkeyData';

class TurkeyInfo extends Component {

    render() {
        return(
            <Fragment>
                <Navigation />
                <ImagesWrapperTurkey />
                <TurkeyData />
            </Fragment>

        )
    }

}

export default TurkeyInfo;