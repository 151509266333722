import React from 'react';
import {
MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardHeader,
MDBRow, MDBContainer
} from "mdbreact";

const ColombiaData = () => {
return (
<MDBContainer>
  <MDBRow center className="pt-5">
    <h2>Datos de Colombia</h2>
  </MDBRow>
  <MDBRow center>
    <MDBCard border="primary" className="m-3" style={{ maxWidth: "18rem" }}>
      <MDBCardHeader>Ubicación</MDBCardHeader>
      <MDBCardBody className="text-primary">
        <MDBCardTitle tag="h5">Norte de Suramérica</MDBCardTitle>
        <MDBCardText>
          Con puertos en el oceano atlántico y pacífico. Además de fácil acceso a grandes mercados como Estados Unidos y Brasil.
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>

    <MDBCard border="secondary" className="m-3" style={{ maxWidth: "18rem" }}>
      <MDBCardHeader>Competitividad</MDBCardHeader>
      <MDBCardBody className="text-secondary">
        <MDBCardTitle tag="h5">Ranking de Competitividad</MDBCardTitle>
        <MDBCardText>
          Está en el puesto 57 del ranking de competitividad mundial, de los 141 paises analizados.
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>

    <MDBCard border="success" className="m-3" style={{ maxWidth: "18rem" }}>
      <MDBCardHeader>Header</MDBCardHeader>
      <MDBCardBody className="text-success">
        <MDBCardTitle tag="h5">Success card title</MDBCardTitle>
        <MDBCardText>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>

    <MDBCard border="danger" className="m-3" style={{ maxWidth: "18rem" }}>
      <MDBCardHeader>Header</MDBCardHeader>
      <MDBCardBody className="text-danger">
        <MDBCardTitle tag="h5">Danger card title</MDBCardTitle>
        <MDBCardText>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>

    <MDBCard border="warning" className="m-3" style={{ maxWidth: "18rem" }}>
      <MDBCardHeader>Header</MDBCardHeader>
      <MDBCardBody className="text-warning">
        <MDBCardTitle tag="h5">Warning card title</MDBCardTitle>
        <MDBCardText>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>

    <MDBCard border="info" className="m-3" style={{ maxWidth: "18rem" }}>
      <MDBCardHeader>Header</MDBCardHeader>
      <MDBCardBody className="text-info">
        <MDBCardTitle tag="h5">Info card title</MDBCardTitle>
        <MDBCardText>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>

    <MDBCard border="light" className="m-3" style={{ maxWidth: "18rem" }}>
      <MDBCardHeader>Header</MDBCardHeader>
      <MDBCardBody>
        <MDBCardTitle tag="h5">Light card title</MDBCardTitle>
        <MDBCardText>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>

    <MDBCard border="dark" className="m-3" style={{ maxWidth: "18rem" }}>
      <MDBCardHeader>Header</MDBCardHeader>
      <MDBCardBody className="text-dark">
        <MDBCardTitle tag="h5">Dark card title</MDBCardTitle>
        <MDBCardText>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>
  </MDBRow>
</MDBContainer>
);
};

export default ColombiaData;