import React, { Component } from "react";
import { MDBMask } from "mdbreact";
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import Navigation from '../Navigation/Navigation';
import WideCard from '../WideCard/WideCard';
import Footer from '../Footer/Footer';
import { Parallax } from "react-parallax";
import img1 from '../../images/landscape-events2.jpg';

counterpart.registerTranslations('es', {
  copy: {
      p9: 'EVENTOS',
      p10: 'Breve Descripción de los Eventos'
  }
})
 
counterpart.registerTranslations('en', {
  copy: {
      p9: 'EVENTS',
      p10: 'Brief Description of Events'
  }
})
 
counterpart.registerTranslations('tk', {
   copy: {
       p9: "Versión Turca P1",
       p10: 'Versión Turca P2'
   }
})


const styles = {
  fontFamily: "sans-serif",
  textAlign: "center"
};

const stylesTitle = {
    fontSize: 50,
    fontWeight: 500
}

class EventsAsilat extends Component {

  constructor() {
    super()
  }

  state = {
      language: localStorage.getItem('language')
    }
    
  componentDidMount() {
    if (localStorage.getItem('language')) {
        this.setState({ language: localStorage.getItem('language') });
    } else {
        this.setState({ language: 'es' });
        localStorage.setItem('language', 'es');
    }
  }

  render() {
    counterpart.setLocale(localStorage.getItem('language'));
    return (
      <div>
      <Navigation/>
      
      <div style={styles}>
        <Parallax bgImage={ img1 } strength={200}>
          <div style={{ height: 300 }}>
            <MDBMask className="flex-center flex-column text-white text-center" overlay="black-strong">
              <Translate content="copy.p9" component="p" style={ stylesTitle }/>
              <Translate content="copy.p10" component="h6"/>
            </MDBMask>
          </div>
        </Parallax>
      </div>
      <br />
      <WideCard />
      <Footer />
    </div>
    );
  }

}
export default EventsAsilat;
