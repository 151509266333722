import React, { Component } from "react";
import { Form, FormGroup, Input, Label, Button, Container, Row, Col } from 'reactstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ContactPage extends Component {
    
  notify = () => toast("Wow so easy !");


    constructor(props) {
        super(props)

        this.state = this.getInitialState();
        
        this.handleSubmit = this.handleSubmit.bind(this)
        this.notify = this.notify.bind(this)
    }

    getInitialState = () => ({
      fields: {
        name:'',
        email:'',
        subject:'',
        message:''
      },
      errors: {}
    })


    handleValidation(){
      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;

      //Name
      if(!fields["name"]){
         formIsValid = false;
         errors["name"] = "Cannot be empty";
      }

      if(typeof fields["name"] !== "undefined"){
         if(!fields["name"].match(/^[a-zA-Z\s]+$/)){
            formIsValid = false;
            errors["name"] = "Only letters";
         }        
      }

      //Email
      if(!fields["email"]){
         formIsValid = false;
         errors["email"] = "Cannot be empty";
      }

      if(typeof fields["email"] !== "undefined"){
         let lastAtPos = fields["email"].lastIndexOf('@');
         let lastDotPos = fields["email"].lastIndexOf('.');

         if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
            formIsValid = false;
            errors["email"] = "Email is not valid";
          }
      }
     
      //Subject
      if(!fields["subject"]){
        formIsValid = false;
        errors["subject"] = "Cannot be empty";
      }

      if(typeof fields["subject"] !== "undefined"){
        if(!fields["subject"].match(/^[a-zA-Z\s]+$/)){
          formIsValid = false;
          errors["subject"] = "Only letters";
        }        
      }

      //message
      if(!fields["message"]){
        formIsValid = false;
        errors["message"] = "Cannot be empty";
      }

      if(typeof fields["message"] !== "undefined"){
        if(!fields["message"].match(/^[a-zA-Z\s\n]+$/)){
          formIsValid = false;
          errors["message"] = "Only letters";
        }        
      }

     this.setState({errors: errors});
     return formIsValid;
 }

    async handleSubmit(e) {
        e.preventDefault()
 

        if(this.handleValidation()){
          
          const { name, email, subject, message } = this.state.fields

          try {
            const form = await axios.post('https://us-central1-asilat.cloudfunctions.net/sendFormToEmail/', {
              name,
              email,
              subject,
              message
            })
            this.setState(this.getInitialState());
            toast.info("Email enviado con éxito!");
          }
          catch(error) {
            toast.error('Error: '.error);
          }
       }else{
        toast.error('El formulario tiene errores');
       }
    }

    handleChange(field, e){         
      let fields = this.state.fields;
      fields[field] = e.target.value;        
      this.setState({fields});
    }

    render() {

        return (
          <Container>
            <Row>
              <Col sm="12" md={{ size: 12, offset: 4 }}>
                <h2>Contáctenos</h2>
              </Col>
            </Row>
            <Row className="pt-5">
              <Col lg="5" className="lg-0 mb-4">
                <fieldset>
                  <Form name="contactform" className="contactform" onSubmit={ this.handleSubmit } >
                    <FormGroup>
                      <Label for="name">Name</Label>
                      <Input
                        ref="name" 
                        size="30"
                        onChange={this.handleChange.bind(this, "name")} 
                        value= {this.state.fields["name"]}
                        type="text"
                        name="name"
                      />
                      <span style={{color: "red"}}>{this.state.errors["name"]}</span>
                    </FormGroup>

                    <FormGroup>
                      <Label for="email">Email</Label>
                      <Input
                        ref="email"
                        size="30"
                        onChange={this.handleChange.bind(this, "email")} 
                        value={this.state.fields["email"]}
                        type="email"
                        name="email"
                      />
                      <span style={{color: "red"}}>{this.state.errors["email"]}</span>
                    </FormGroup>

                    <FormGroup>
                      <Label for="subject">Subject</Label>
                      <Input
                        ref="subject"
                        size="30"
                        onChange={this.handleChange.bind(this, "subject")} 
                        value={this.state.fields["subject"]}
                        type="text"
                        name="subject"
                      />
                      <span style={{color: "red"}}>{this.state.errors["subject"]}</span>
                    </FormGroup>

                    <FormGroup>
                      <Label for="message">Message</Label>
                      <Input
                        ref="message"
                        size="100"
                        onChange={this.handleChange.bind(this, "message")} 
                        value={this.state.fields["message"]}
                        type="textarea"
                        name="message"
                      />
                      <span style={{color: "red"}}>{this.state.errors["message"]}</span>
                    </FormGroup>
                    <div>
                      <Button>Submit</Button>
                      <ToastContainer 
                          className='toast-container'
                          toastClassName="dark-toast"
                      />
                    </div>
                  </Form>
                </fieldset>
              </Col>

              <Col lg="7">
              <div
                    id="map-container"
                    className="rounded z-depth-1-half map-container"
                    style={{ height: "400px" }}
                  > 
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.531591248501!2d-75.57218338479454!3d6.1933663955163745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e4682891671ff9b%3A0xdb7e773ac7f6d8aa!2sPinares%20del%20Castillo!5e0!3m2!1ses-419!2sco!4v1584811015897!5m2!1ses-419!2sco"
                      title="This is a unique title"
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      style={{ border: 0 }}
                    />
              </div>

              </Col>
            </Row>
          </Container>
        )
    }
}

export default ContactPage;