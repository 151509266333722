import React, { Component } from "react";
import { Container, Row, Col } from 'reactstrap';
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import purposeImg from '../../images/purpose-image.jpg'; 
import './Purpose.css'; //Import here your file style

counterpart.registerTranslations('es', {
   copy: {
       p5: 'Nuestro Propósito',
       p6: 'Más que facilitar el intercambio cultural y comercial entre Turquía, Colombia y los demás países de la región. ASILAT tiene como propósito promover la transmisión de experiencias y modelos que conlleven a construir sistemas económicos más eficientes e independientes. Esto es, Turquía y los países latinoamericanos tienen economías con características similares. Así mismo, poseen particularidades que las hacen resaltar en ciertos aspectos.',
       p7: 'Particularmente Turquía ha logrado elevar el nivel de su economía, alcanzando una tasa promedio de crecimiento anual superior al 7% (2010 – 2020). Se han desarrollado productos y servicios de calidad en líneas no tradicionales, que por su valor agregado se vienen ganando un espacio en ámbitos internacionales.',
       p8: 'Más Info'
   }
})
  
counterpart.registerTranslations('en', {
   copy: {
       p5: 'Our Purpose',
       p6: 'More than facilitating cultural and commercial exchange between Turkey, Colombia and the other countries in the region. ASILAT aims to promote the transmission of experiences and models that lead to building more efficient and independent economic systems. That is, Turkey and the Latin American countries have economies with similar characteristics. Likewise, they have particularities that make them stand out in certain aspects.',
       p7: 'Particularmente Turquía ha logrado elevar el nivel de su economía, alcanzando una tasa promedio de crecimiento anual superior al 7% (2010 – 2020). Se han desarrollado productos y servicios de calidad en líneas no tradicionales, que por su valor agregado se vienen ganando un espacio en ámbitos internacionales.',
       p8: 'More Info'
   }
 })
  
counterpart.registerTranslations('tk', {
    copy: {
        p5: "Versión Turca P5",
        p6: 'Versión Turca P6',
        p7: 'Version Turca P7',
        p8: 'Versión Turca P8'
    }
 })

 
 const stylesTitle = {
     fontSize: 30,
     fontWeight: 500
    }
    
    const stylesContent = {
        fontSize: 14,
        fontWeight: 200
    }
    
const containerStyles = {
    paddingTop: 20,
    paddingBottom: 20
}

class Purpose extends Component {

    constructor() {
        super()
      }

      state = {
          language: localStorage.getItem('language')
        }
        
        componentDidMount() {
        if (localStorage.getItem('language')) {
            this.setState({ language: localStorage.getItem('language') });
        } else {
            this.setState({ language: 'es' });
            localStorage.setItem('language', 'es');
        }
    }
    
    render() {
        counterpart.setLocale(localStorage.getItem('language'));
        return (
            <Container style={ containerStyles }>
                <Row>
                    <Col md="6" className="p-2">
                        <img src={ purposeImg } className="img-fluid" alt="" />
                    </Col>
                    <Col md="6">
                        <Container>
                            <Translate content="copy.p5" component="h2" style={ stylesTitle }/>
                            <Translate content="copy.p6" component="p" style={ stylesContent }/>
                            <Translate content="copy.p7" component="p" style={ stylesContent }/>
                            <Translate content="copy.p8" component="button" className="btn btn-primary" />
                        </Container>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Purpose;