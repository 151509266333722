import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBView, MDBMask, MDBCardTitle, MDBCardText, MDBBtn } from 'mdbreact';
import video1 from '../../videos/event.mp4'
import './WideCard.css';

class WideCard extends Component {
  render() {
    return (
      <MDBContainer>
        <MDBRow>
          <MDBCol className="column-1 px-2 mb-r">
            <MDBCard className="default-color-dark">
              <MDBView>

              <video className='videoTag' autoPlay loop muted height="500" width="100%">
                <source src={video1} type='video/mp4' />
              </video>
              <div className="header-overlay"></div>

              <MDBMask overlay="white-slight" />
              </MDBView>
              <MDBCardBody className="text-center">
                <MDBCardTitle className="white-text">Titulo del Evento</MDBCardTitle>
                <MDBCardText className="white-text">Descripción del Evento.</MDBCardText>
                <MDBBtn outline color="white" size="md">Registrarse</MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  };
}

export default WideCard;