import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import Media from 'react-media';
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import logo from '../../images/logos/logo-asilat3.png';
import english from '../../images/english.png';
import spanish from '../../images/spanish.png';
import './Navigation.css';

counterpart.registerTranslations('es', {
  copy: {
    nav1: 'Inicio',
    nav2: 'Acerca',
    nav3: 'Turquía',
    nav4: 'Servicios',
    nav5: 'Eventos',
    nav6: 'Contacto'
  }
})

counterpart.registerTranslations('en', {
  copy: {
    nav1: 'Home',
    nav2: 'About',
    nav3: 'Turkey',
    nav4: 'Services',
    nav5: 'Events',
    nav6: 'Contact'
  }
});

counterpart.registerTranslations('tk', {
  copy: {
    nav1: 'Versión Turca nav1',
    nav2: 'Versión Turca nav2',
    nav3: 'Versión Turca nav3',
    nav4: 'Versión Turca nav4',
    nav5: 'Versión Turca nav5',
    nav6: 'Versión Turca nav6'
  }
});


const styles1 = {
  background: '#e5e5e5',
  color: "black"
};

const styles2 = {
  background: 'none',
  color: "grey"
};

class Navigation extends Component  {
  
  constructor() {
    super()

    this.state = {
      isOpen: false,
      spanishLanguage: true,
      englishLanguage: false,
      section: 'home',
      language: 'es'
    }
  }
   

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  changeLanguage = () => {
    if (this.state.spanishLanguage && !this.state.englishLanguage) {
      this.setState({ englishLanguage: true, spanishLanguage: false, language: 'en' });
      counterpart.setLocale('en');
      localStorage.setItem('language', 'en');
    } else if (!this.state.spanishLanguage && this.state.englishLanguage) {
      this.setState({ englishLanguage: false, spanishLanguage: true, language: 'es' })
      counterpart.setLocale('es');
      localStorage.setItem('language', 'es');
    }
  }

  handleClick() {
    this.setState({ section: 'services' });
  }
  
  isActive = (value) => {
    if (value === this.state.section) {
      return 'nav-link ' + 'active';
    } else {
      return 'nav-link'
    }
  }

  isSelected = (value) => {
    if (value === this.state.section) {
      return styles1;
    } else {
      return styles2
    }
  }

  componentDidMount() {
    localStorage.setItem('language', 'es');
    counterpart.setLocale('es'); 
    }
      
  render() {
    counterpart.setLocale(localStorage.getItem('language'));

    return (
      <Navbar collapseOnSelect expand="lg" bg="white" variant="light">
        <Navbar.Brand href="/home">
          <Media queries={{
            small: "(max-width: 599px)",
            medium: "(min-width: 600px) and (max-width: 1199px)",
            large: "(min-width: 1200px)"
          }}>
          {matches => (
            <Fragment>
              {matches.small && <img src={ logo } width="350" height="50" alt="logo-asilat" />}
              {matches.medium && <img src={ logo } width="450" height="60" alt="logo-asilat" />}
              {matches.large && <img src={ logo } width="450" height="60" alt="logo-asilat" />}
            </Fragment>
          )}
          </Media>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="justify-content-end mr-auto" style={{ width: "100%" }} defaultActiveKey='/home'>
            <Nav.Link as={NavLink} to='/home' exact><Translate content="copy.nav1" component="p" className="p-0 m-0"></Translate></Nav.Link>
            <NavDropdown title={ <Translate content="copy.nav2" style={{ color: '#969393' }}></Translate> }>
              <NavDropdown.Item eventKey="/turkey" as={NavLink} to='/turkey'><Translate content="copy.nav3" component="p" className="p-0 m-0"></Translate></NavDropdown.Item>
              <NavDropdown.Item eventKey="/colombia" as={NavLink} to='/colombia'>Colombia</NavDropdown.Item>
          </NavDropdown>
            <Nav.Link eventKey="/services" as={NavLink} to='/services'><Translate content="copy.nav4" component="p" className="p-0 m-0"></Translate></Nav.Link>
            <Nav.Link eventKey="/events" as={NavLink} to='/events'><Translate content="copy.nav5" component="p" className="p-0 m-0"></Translate></Nav.Link>
            <Nav.Link eventKey="/contact" as={NavLink} to='/contact'><Translate content="copy.nav6" component="p" className="p-0 m-0"></Translate></Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href="#">  
              <img style={{ display: (this.state.englishLanguage && !this.state.spanishLanguage) ? 'block' : 'none' }} onClick={ this.changeLanguage } src={ spanish } width="30" height="30" alt="spanish" />
              <img style={{ display: (!this.state.englishLanguage && this.state.spanishLanguage) ? 'block' : 'none' }} onClick={ this.changeLanguage } src={ english } width="30" height="30" alt="english" />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      );
    }
}


export default Navigation;