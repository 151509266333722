import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import logo from '../../images/logos/logo-asilat-horizontal.png';
import whatsApp from '../../images/whatsapp.png';

const sloganTextStyle = {
  paddingTop: 10
}

const whatsAppStyles = {
  maxWidth: 50
}

const footerTitle = {
  fontWeight: 500
}

const Footer = () => {
  return (
    <MDBFooter color="elegant-color" className="font-small pt-4 mt-4">
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow>
          <MDBCol md="3">
            <img src={ logo } width="110" height="30" alt="logo-asilat" />
            <p style={ sloganTextStyle }>
              Aqui va el slogan de Asilat
            </p>
          </MDBCol>
          <MDBCol md="3">
            <h5 style={ footerTitle } className="title">Latam</h5>
            <ul>
            <li className="list-unstyled">
                <p>Medellín Colombia</p>
              </li>
              <li className="list-unstyled">
                <p>Cra. 35 No. 8 Sur 22</p>
              </li>
              <li className="list-unstyled">
                <p>Tel: +57 302 261 5252</p>
              </li>
              <li className="list-unstyled">
                <p>info@asilat.org</p>
              </li>
            </ul>
          </MDBCol>
          <MDBCol md="3">
            <h5 style={ footerTitle } className="title">Istanbul</h5>
            <ul>
              <li className="list-unstyled">
                <p>Halaskargazi Caddesi</p>
              </li>
              <li className="list-unstyled">
                <p>No. 8/11, Sisli</p>
              </li>
              <li className="list-unstyled">
                <p>Tel: +90 543 718 4571</p>
              </li>
              <li className="list-unstyled">
                <p>infoturkey@asilat.org</p>
              </li>
            </ul>
          </MDBCol>
          <MDBCol md="3">
            <h5 style={ footerTitle } className="title">WhatsApp</h5>
            <ul>
              <li className="list-unstyled">
              <img style={ whatsAppStyles } src={ whatsApp } className="img-fluid" alt="" />
              </li>
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright <p> Asilat.org </p>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}

export default Footer;