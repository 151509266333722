import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './components/Home/Home';
import CarouselHome from './components/CarouselHome/CarouselHome';
import ServicesAsilat from './components/ServicesAsilat/ServicesAsilat';
import EventsAsilat from './components/EventsAsilat/EventsAsilat';
import ContactAsilat from './components/ContactAsilat/ContactAsilat';
import ParallaxComponent from './components/ParallaxComponent/ParallaxComponent';
import TurkeyInfo from './components/TurkeyInfo/TurkeyInfo';
import ColombiaInfo from './components/ColombiaInfo/ColombiaInfo';

const Routes = () => {
    return(
        <Switch>
            <Route exact path="/" component={ Home } /> 
            <Route exact path="/carousel" component={ CarouselHome } />
            <Route exact path="/services" component={ ServicesAsilat } />
            <Route exact path="/events" component={ EventsAsilat } />
            <Route exact path="/contact" component={ ContactAsilat } />
            <Route exact path="/parallax" component={ ParallaxComponent } />
            <Route exact path="/turkey" component={ TurkeyInfo } />
            <Route exact path="/colombia" component={ ColombiaInfo } />
        </Switch>
    )
}

export default Routes;