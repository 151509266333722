import React, { Component } from "react";
import { MDBMask } from "mdbreact";
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import { Parallax } from "react-parallax";
import img1 from '../../images/landscape-about-asilat.jpg';

counterpart.registerTranslations('es', {
  copy: {
        paraboutasilat1: 'Acerca de Asilat'
  }
})
 
counterpart.registerTranslations('en', {
  copy: {
        paraboutasilat1: 'About Asilat'
  }
})
 
counterpart.registerTranslations('tk', {
   copy: {
        paraboutasilat1: "Versión Turca Paraboutasilat1"
   }
})

  const styles = {
      fontFamily: "sans-serif",
      textAlign: "center"
    };

  const insideStyles = {
    background: "black",
    color: "white",
    fontSize: 20,
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)"
  };  
  
  class ParallaxNews extends Component {
    render() {
      return (
        <div style={styles}>
          <Parallax bgImage={ img1} strength={200}>
            <div style={{ height: 300 }}>
                <Translate content="copy.paraboutasilat1" component="p" style={insideStyles}/>
            <MDBMask className="flex-center" overlay="black-strong" ></MDBMask>
            </div>
          </Parallax>
        </div>
      )
    }
}

export default ParallaxNews;