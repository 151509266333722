import React, { Component } from "react";
import { MDBMask } from "mdbreact";
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import { Parallax } from "react-parallax";
import img1 from '../../images/landscape-events.jpg';

counterpart.registerTranslations('es', {
  copy: {
      parevent1: 'Información de Interés'
  }
})
 
counterpart.registerTranslations('en', {
  copy: {
      parevent1: 'Information of Interest'
  }
})
 
counterpart.registerTranslations('tk', {
   copy: {
       parevent1: "Versión Turca P12"
   }
})

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
  };
  const insideStyles = {
    background: "black",
    color: "white",
    fontSize: 20,
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)"
  };  
  
    class ParallaxEvents extends Component {
        render() {
          return (
            <div style={styles}>
              <Parallax bgImage={ img1} strength={200}>
                <div style={{ height: 300 }}>
                    <Translate content="copy.parevent1" component="p" style={insideStyles}/>
                <MDBMask className="flex-center" overlay="black-strong" ></MDBMask>
                </div>
              </Parallax>
            </div>
          )
        }
    }

export default ParallaxEvents;