import React from 'react';
import {
MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardHeader,
MDBRow, MDBContainer
} from "mdbreact";

const TurkeyData = () => {
return (
<MDBContainer>
  <MDBRow center className="pt-5">
    <h2>Datos de Turquía</h2>
  </MDBRow>
  <MDBRow center>
    <MDBCard border="primary" className="m-3" style={{ maxWidth: "18rem" }}>
      <MDBCardHeader>Ubicación</MDBCardHeader>
      <MDBCardBody className="text-primary">
        <MDBCardTitle tag="h5">Entre Europa y Asia</MDBCardTitle>
        <MDBCardText>
          Es el país que mezcla la cultura occidental y oriental heredando con ello una identidad única.
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>

    <MDBCard border="secondary" className="m-3" style={{ maxWidth: "18rem" }}>
      <MDBCardHeader>Competitividad</MDBCardHeader>
      <MDBCardBody className="text-secondary">
        <MDBCardTitle tag="h5">Capacidad de producción</MDBCardTitle>
        <MDBCardText>
          Produce los bienes y servicios que consume LATAM bajo la calidad y los precios requeridos.
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>

    <MDBCard border="success" className="m-3" style={{ maxWidth: "18rem" }}>
      <MDBCardHeader>Innovación</MDBCardHeader>
      <MDBCardBody className="text-success">
        <MDBCardTitle tag="h5">Tecnología Aplicada</MDBCardTitle>
        <MDBCardText>
          Con potencial para convertirse en proveedor clave de bienes y servicios tanto tradicionales como no tradicionales
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>

    <MDBCard border="danger" className="m-3" style={{ maxWidth: "18rem" }}>
      <MDBCardHeader>Apertura Económica</MDBCardHeader>
      <MDBCardBody className="text-danger">
        <MDBCardTitle tag="h5">Impo y Expo</MDBCardTitle>
        <MDBCardText>
          País dispuesto a establecer relaciones comerciales de largo plazo en ambas direccionales.
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>

    <MDBCard border="warning" className="m-3" style={{ maxWidth: "18rem" }}>
      <MDBCardHeader>Header</MDBCardHeader>
      <MDBCardBody className="text-warning">
        <MDBCardTitle tag="h5">Warning card title</MDBCardTitle>
        <MDBCardText>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>

    <MDBCard border="info" className="m-3" style={{ maxWidth: "18rem" }}>
      <MDBCardHeader>Header</MDBCardHeader>
      <MDBCardBody className="text-info">
        <MDBCardTitle tag="h5">Info card title</MDBCardTitle>
        <MDBCardText>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>

    <MDBCard border="light" className="m-3" style={{ maxWidth: "18rem" }}>
      <MDBCardHeader>Header</MDBCardHeader>
      <MDBCardBody>
        <MDBCardTitle tag="h5">Light card title</MDBCardTitle>
        <MDBCardText>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>

    <MDBCard border="dark" className="m-3" style={{ maxWidth: "18rem" }}>
      <MDBCardHeader>Header</MDBCardHeader>
      <MDBCardBody className="text-dark">
        <MDBCardTitle tag="h5">Dark card title</MDBCardTitle>
        <MDBCardText>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>
  </MDBRow>
</MDBContainer>
);
};

export default TurkeyData;