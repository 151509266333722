import React, { Component, Fragment } from 'react';
import Navigation from '../Navigation/Navigation';
import ImagesWrapperColombia from '../ImagesWrapperColombia/ImagesWrapperColombia'
import ColombiaData from '../ColombiaData/ColombiaData';

class ColombiaInfo extends Component {

    render() {
        return(
            <Fragment>
                <Navigation />
                <ImagesWrapperColombia />
                <ColombiaData />
            </Fragment>

        )
    }

}

export default ColombiaInfo;