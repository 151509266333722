import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import img100 from '../../images/logos/cccolomboturca.jpg';
import img200 from '../../images/logos/comfenalco.jpg';

class Partners extends React.Component {
  render() {
    return (
      
      <OwlCarousel
        className="owl-theme"
        loop
        margin={100}
        nav
        autoplay={true}
        autoplayTimeout={1500}
        autoplayHoverPause={true}
      >
        <div className="item"><img src={ img100 } width="200" height="150" alt="logo-asilat"/></div>
        <div className="item"><img src={ img200 } width="200" height="150" alt="logo-asilat"/></div>

      </OwlCarousel>
    );
  }
}

export default Partners;