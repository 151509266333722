import React, { Component } from "react";
import { MDBMask, MDBContainer } from "mdbreact";
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import { Parallax } from "react-parallax";
import img1 from '../../images/landscape-contact.jpg';
import Navigation from '../Navigation/Navigation';
import ContactForm from '../ContactForm/ContactForm';
import Footer from '../Footer/Footer';

counterpart.registerTranslations('es', {
  copy: {
      con1: 'CONTACTO',
      con2: 'Inquietudes y sugerencias'
  }
})
 
counterpart.registerTranslations('en', {
  copy: {
      con1: 'CONTACT',
      con2: 'Concerns and Suggestions'
  }
})
 
counterpart.registerTranslations('tk', {
   copy: {
       con1: "Versión Turca con1",
       con2: 'Versión Turca con2'
   }
})


const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
  };
  
const stylesTitle = {
    fontSize: 50,
    fontWeight: 500
}

class ContactAsilat extends Component {

  constructor() {
    super()
  }

    
  componentDidMount() {
    if (localStorage.getItem('language') != '') {
        this.setState({ language: localStorage.getItem('language') });
    } else {
        this.setState({ language: 'es' });
        localStorage.setItem('language', 'es');
    }
  }

  render() {
    return (
      <div>
        <Navigation/>
        
        <div style={styles}>
          <Parallax bgImage={ img1 } strength={200}>
            <div style={{ height: 300 }}>
              <MDBMask className="flex-center flex-column text-white text-center" overlay="black-strong">
                <Translate content="copy.con1" component="p" style={ stylesTitle }/>
                <Translate content="copy.con2" component="h6"/>
              </MDBMask>
            </div>
          </Parallax>
        </div>
        <MDBContainer className="pt-5">
            <ContactForm /> 
        </MDBContainer>
        <Footer />
      </div>
    )
  }
}


export default ContactAsilat;
