import React, { Component } from 'react';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import img1 from '../../images/news/news-img-1.jpg';
import img2 from '../../images/news/news-img-2.jpg';
import img3 from '../../images/news/news-img-3.jpg';
import img4 from '../../images/news/news-img-4.jpg';


  class News extends Component {
    state = {
      flipped: false
    }
    
    handleFlipping = () => {
      this.setState({ flipped: !this.state.flipped });
    }
    
    render() {    
      return (
        <MDBContainer>
          <MDBRow className="d-flex justify-content-center">
            <MDBCol xs="12" sm="6" md="3" className="pt-3 d-flex justify-content-center">
              <MDBCard style={{ width: "16rem" }}>
                <MDBCardImage className="img-fluid" src={ img3 } waves />
                <MDBCardBody>
                  <MDBCardTitle>Titular de la Noticia</MDBCardTitle>
                  <MDBCardText>
                    Descripción de la noticia.
                  </MDBCardText>
                  <MDBBtn color="brown" href="#">Más info</MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol xs="12" sm="6" md="3" className="pt-3 d-flex justify-content-center">
              <MDBCard style={{ width: "16rem" }}>
                <MDBCardImage className="img-fluid" src={ img4 } waves />
                <MDBCardBody>
                  <MDBCardTitle>Titular de la Noticia</MDBCardTitle>
                  <MDBCardText>
                    Descripción de la noticia.
                  </MDBCardText>
                  <MDBBtn color="brown" href="#">Más info</MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol xs="12" sm="6" md="3" className="pt-3 d-flex justify-content-center">
              <MDBCard style={{ width: "16rem" }}>
                <MDBCardImage className="img-fluid" src={ img2 } waves />
                <MDBCardBody>
                  <MDBCardTitle>Nombre de la noticia</MDBCardTitle>
                  <MDBCardText>
                    Descripción de la noticia.
                  </MDBCardText>
                  <MDBBtn color="brown" href="#">Más info</MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol xs="12" sm="6" md="3" className="pt-3 d-flex justify-content-center">
              <MDBCard style={{ width: "16rem" }}>
                <MDBCardImage className="img-fluid" src={ img1 } waves />
                <MDBCardBody>
                  <MDBCardTitle>Titular de la Noticia</MDBCardTitle>
                  <MDBCardText>
                    Descripción de la noticia.
                  </MDBCardText>
                  <MDBBtn color="brown" href="#">Más info</MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      )
    }
  }
    

export default News;